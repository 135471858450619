// paper & background
$paper: #ffffff;

// primary
$primaryLight: #DAEBFF;
$primaryMain: #27A9E0;;
$primaryDark: #1e88e5;
$primary200: #E7F5FF;
$primary800: #1565c0;

// secondary
$secondaryLight: #f2e9e4;
$secondaryMain: #F27522;
$secondaryDark: #e6580b;
$secondary200: #d8ae98;
$secondary800: #a25327;

$blueMain:#568BF3;
$purpleMain:#815FE0;

// success Colors
$successLight: #49B979;
$success200: #EAF9F0;
$successMain: #009E3B;
$successDark: #028533;

// error
$errorLight: #ef9a9a;
$error200:#f6ebeb;
$errorMain: #D90012;
$errorDark: #c62828;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$greyLight: #e0e0e0;
$greyDark: #3E3E3E;
$grey600:#59595B;
$greyMain: #9e9e9e;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4
$greyBackground:#fafafa;

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #DAEBFF;
$darkPrimaryMain: #27A9E0;
$darkPrimaryDark: #1e88e5;

// secondary dark
$darkSecondaryLight: #f2e9e4;
$darkSecondaryMain: #F27522;
$darkSecondaryDark: #e6580b;

// text variants
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;
$darkText:#000000;
$black: #000000;
$white: #ffffff;
$textBlack:#292929;
$divider:#59595B;
// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;
    textBlack:$textBlack;
    divider:$divider;
    greyBackground:$greyBackground;
    primary200:$primary200;
    primary800:$primary800;
    secondary200:$secondary200;
    secondary800:$secondary800;
    success200:$success200;
    // primary
    primaryLight: $primaryLight;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;

    // secondary
    secondaryLight: $secondaryLight;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;

    // success
    successLight: $successLight;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;
    error200: $error200;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    greyLight: $greyLight;
    greyDark: $greyDark;
    greyMain: $greyMain;
    grey600:$grey600;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkText: $darkText;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;

    blueMain :$blueMain ;
    purpleMain:$purpleMain;

    white:$white;
    black:$black;
}