.blog-item:hover .category-card {
    opacity: 0.9; /* Decrease opacity on hover */
}

.blog-item:hover .category-title {
    text-decoration: underline; /* Underline category name on */
}

.card:hover .category-card {
    opacity: 0.9;
}

.card .category-card {
    border-radius: 15px;
    padding: 8px;
}
.card:hover .category-title {
    text-decoration: underline;
}

.blog-item {
    margin-top: 10px;
}
.react-multi-carousel-track {
    gap: 0px;
}

.card {
    margin: 10px 0px 0px 10px;
    background-color: #f3f3f3;
    min-height: 425px;
    max-height: 425px;
}

.carousel-gallery .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px) !important;
    opacity: 0.5;
}

.carousel-gallery .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px) !important;
    opacity: 0.5;
}

/* .carousel-gallery.react-multi-carousel-list:hover .react-multiple-carousel__arrow {
    opacity: 1;
} */

.carousel-gallery .card {
    min-height: 409px;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
    background: #000;
}

.slider {
    margin: 0 20px;
    overflow: 'hidden';
    padding: 2rem 0;
}

.slider img {
    width: 100%;
    border-radius: 10px;
}

.react-multi-carousel-list {
    padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
    border: none;
    background: rgb(255, 68, 68);
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: rgb(255, 68, 68) !important;
}
