// /* Slider */
// .slick-slider {
//   position: relative;
//   display: block;
//   box-sizing: border-box;

//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;

//   -webkit-touch-callout: none;
//   -khtml-user-select: none;
//   -ms-touch-action: pan-y;
//   touch-action: pan-y;
//   -webkit-tap-highlight-color: transparent;

//   @media (min-width: 768px) {
//     .slick-caption {
//       display: block !important;
//     }
//   }

//   .slick-caption {
//     padding-bottom: 45px;
//     position: absolute;
//     right: 15%;
//     bottom: 20px;
//     left: 15%;
//     padding-top: 20px;
//     color: #ffffff;
//     text-align: center;
//     z-index: 3;
//     display: none;
//   }
//   .slick-slide {
//     > div:first-child {
//       position: relative;
//     }
//   }
//   .slick-icons {
//     position: relative;
//     top: 5px;
//   }
//   // .slick-image {
//   //   width: 100% !important;
//   //   display: inline-flex !important;
//   // }
// }

// .slick-list {
//   position: relative;

//   display: block;
//   overflow: hidden;

//   margin: 0;
//   padding: 0;
// }
// .slick-list:focus {
//   outline: none;
// }
// .slick-list.dragging {
//   cursor: pointer;
//   cursor: hand;
// }

// .slick-slider .slick-track,
// .slick-slider .slick-list {
//   -webkit-transform: translate3d(0, 0, 0);
//   -moz-transform: translate3d(0, 0, 0);
//   -ms-transform: translate3d(0, 0, 0);
//   -o-transform: translate3d(0, 0, 0);
//   transform: translate3d(0, 0, 0);
// }

.slick-track {
  display:flex;
  // position: relative;
  // top: 0;
  // left: 0;
  // width: max-content !important;
  // display: block;
  // margin-left: auto;
  // margin-right: auto;
}
// .slick-track:before,
// .slick-track:after {
//   display: table;

//   content: "";
// }
// .slick-track:after {
//   clear: both;
// }
// .slick-loading .slick-track {
//   visibility: hidden;
// }

.slick-slide {
  display: none;
  float: left;
  margin: 0 2px;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
// .slick-slide img {
//   display: block;
// }
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
  // width: 160px !important;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  // width: 20px;
  height: 100%;
  padding: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  color: transparent;
  outline: none;
  background: transparent;
  width: 15%;
  z-index: 2;
  opacity: 0.5;
}
.slick-prev {
  left: 0;
  &::before {
    content: "\f053";
    font-weight: 600;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: white;
    font-size: 30px;
    width: 100%;
  }
}
.slick-next {
  right: 0;
  &::before {
    content: "\f054";
    font-weight: 600;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: #fff;
    font-size: 30px;
    width: 100%;
  }
}
.slick-prev, .slick-next{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    border: none;
    z-index: 3;
}
.slick-next{
  right:0
}
.slick-slider {
  overflow: hidden;
}
.rating-slider .slick-slide{
  box-shadow: unset;
  padding:20px 0;
}
.slick-center {
  padding: 20px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.slick-list {
  z-index: 1;
}
.slick-dots {
  margin-top: 0;
  margin-bottom: 1rem;
  position: absolute;
  bottom: 5px;
  // margin-right: 15%;
  // margin-left: 15%;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  z-index: 3;
}
.slick-dots li,
.slick-dots li button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 5px;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    content: "\2022";
    text-align: center;
    opacity: 1;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    transition: all 300ms linear;
  }
}
.slick-dots li.slick-active button:before {
  width: 15px;
  height: 15px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  top: -3px;
}
.container {
  padding: 40px;
  background: #419be0;
}
